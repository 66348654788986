import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import NavBarMain from './NavBarMain';
import './css/ClothMaster.css';
import { useNavigate} from 'react-router-dom';

function ClothMaster() {

    const apireq05042024 = process.env.REACT_APP_SERVER_API_URL;
        
    const navigate = useNavigate();
    const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
    const [lastActivityTime, setLastActivityTime] = useState(Date.now());

    const usernameglobal = JSON.parse(localStorage.getItem('username'));
    const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
          if(response.data == "logout") {
            navigatetologin();
          }
          setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      // Initial fetch if usernameglobal is available
      if (usernameglobal) {
        fetchData();
      }

      // Set up interval to fetch data every 5 minutes
      const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }, [usernameglobal]); // Dependency array includes usernameglobal

    useEffect(() => {
      if (usernameglobal === null) {
        navigate('/LoginPage');
      } else {
        // Reset last activity time whenever there's user interaction
        const handleUserActivity = () => {
          setLastActivityTime(Date.now());
        };

        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('touchstart', handleUserActivity); // For touch devices

        return () => {
          window.removeEventListener('mousemove', handleUserActivity);
          window.removeEventListener('keydown', handleUserActivity);
          window.removeEventListener('touchstart', handleUserActivity);
        };
      }
    }, [usernameglobal, navigate]);

    useEffect(() => {
      const sessionTimeout = setTimeout(() => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - lastActivityTime;

        if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
          console.log('Logging out due to inactivity...');
          navigatetologin();
        } else {
          // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
          sessionTimeout.refresh();
        }
      }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

      return () => clearTimeout(sessionTimeout);
    }, [lastActivityTime, navigatetologin]);

    // console.log(UserMasterDataGlobal[0].usertype);
    async function navigatetologin() {
      const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
      localStorage.removeItem('username');
      localStorage.removeItem('localstorage_id');
      navigate('/LoginPage');
    }

    const MySwal = withReactContent(Swal);
    const [formData, setFormData] = useState({
        _id: '',
        cloth_type: '',
        charges_rs: '',
        cloth_img: '',
        operationtype: 'Add Cloth',
    });
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        const updatedFormData = {
            ...formData,
            [name]: value,
        };

        setFormData(updatedFormData);
    };

    const [ClothMasterData, setClothMasterData] = useState([]);

    useEffect(() => {
      // Fetch data from the specified URL
      axios.get(process.env.REACT_APP_SERVER_API_URL+'ClothMaster/show')  
          .then((response) => {
            setClothMasterData(response.data);
            
          })
          .catch((error) => console.error('Error fetching data:', error));
    }, []);

    const [errors, seterrors] = useState({});

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const formData = new FormData(event.target);
        
        var validationerrors = {};

        if(formData.get('cloth_type').trim() !== '') {
        }else{
          validationerrors.cloth_type = "Please enter a valid cloth type.";
        } 
        
        if(formData.get('charges_rs').trim() !== '') {
        }else{
          validationerrors.customer_mobile = "Please enter a charges rs.";
        } 

        seterrors(validationerrors);

        if (Object.keys(validationerrors).length == 0) {
            var data = {
                Companyid: UserMasterDataGlobal?.[0]?.Companyid,
                userid: UserMasterDataGlobal?.[0]?._id,
                cloth_type: formData.get('cloth_type'),
                charges_rs:formData.get('charges_rs'),
                cloth_img:formData.get('cloth_img'),
                operationtype:formData.get('operationtype'),
                _id:formData.get('_id'),
            };

            if(data.operationtype == "Add Cloth") {
                try {
                    const response = await axios.post(process.env.REACT_APP_SERVER_API_URL+'ClothMaster/insert', data); // Correct the URL format

                    if(response.data.message == "Data saved successfully"){
                        MySwal.fire({
                            html: <b>Record Saved Sucessfully!</b>,
                            icon: 'success'
                        })
                    }
                    const newDataItem = {
                        ...data,
                        _id: response.data.cloth._id,
                    };

                    setClothMasterData((prevData) => {
                        return [newDataItem, ...prevData];
                    });
                    
                    emptyinputs();
                } catch (error) {
                    console.error('Error:', error);
                }
            } else {

                try {
                    const response = await axios.post(process.env.REACT_APP_SERVER_API_URL+'ClothMaster/update', data); // Correct the URL format
                    
                    if(response.data.message == "Data updated successfully"){
                        MySwal.fire({
                            html: <b>Record updated Sucessfully!</b>,
                            icon: 'success'
                        })

                        setClothMasterData((prevData) => {
                            const updatedData = [...prevData];
                            const index = updatedData.findIndex((record) => record._id == data._id);
          
                            if (index !== -1) {
                              updatedData[index] = { ...updatedData[index], ...data };
                            }           
                              
                            return updatedData;
                        });
                        
                        emptyinputs();
                    }                    
                } catch (error) {
                    console.error('Error:', error);
                }
            }

        } else {
            console.log(validationerrors);
        }
    }
    
    function emptyinputs(){
        setFormData({
            _id: '',
            cloth_type: '',
            charges_rs: '',
            cloth_img: '',
            operationtype: 'Add Cloth',
        });
    }

    function confirmDelete(event, id) {
        event.preventDefault();

        MySwal.fire({
            title: 'Are you sure you want to delete the record?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                if (result.value) {
                // window.location.href = $(this).attr('href');
                deleteData(id);
                }
            }
        })
    }

    async function deleteData(id) {

        const response = await axios.delete(process.env.REACT_APP_SERVER_API_URL+`ClothMaster/delete/${id}`);
        
        if (response.status === 200) {

            if(response.data==="Cloth deleted successfully") {
                MySwal.fire({
                    html: <b>Record deleted Sucessfully!</b>,
                    icon: 'success'
                })
                setClothMasterData((prevData) => prevData.filter((record) => record._id !== id));
                
            } 
        } 
    }

    function getdatabyrecordarray(record) {

        setFormData({
            _id: record._id,
            cloth_type: record.cloth_type,
            charges_rs: record.charges_rs,
            cloth_img: record.cloth_img,
            operationtype: 'Edit Cloth',
        });
        // following code is to go to top of the page after clicking on edit button.
        window.scrollTo(0, 0)
    }

    const [searchKeyword, setSearchKeyword] = useState('');
    const [filteredCustomerData, setFilteredCustomerData] = useState([]);

    function handleSearchInputChange(event) {
        setSearchKeyword(event.target.value);
        filterCustomerData(event.target.value);
    }

    function filterCustomerData(keyword) {
      const filteredData = ClothMasterData.filter(customer => {
          return (
              customer.cloth_type.toLowerCase().includes(keyword.toLowerCase()) ||
              (customer.charges_rs && customer.charges_rs.toString().toLowerCase().includes(keyword.toLowerCase()))
          );
      });
      setFilteredCustomerData(filteredData);
    }

    useEffect(() => {
        setFilteredCustomerData(ClothMasterData);
    }, [ClothMasterData]);

    //this
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 5;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = filteredCustomerData.slice(firstIndex, lastIndex);
    const npage = Math.ceil(filteredCustomerData.length / recordsPerPage);
    //const numbers = [...Array(npage + 1).keys()].slice(1);
    const getVisiblePageNumbers = (currentPage, totalPages) => {
        // Handle cases with 5 or fewer pages
        if (totalPages <= 5) {
        return [...Array(totalPages + 1).keys()].slice(1);
        }
    
        // Handle cases with more than 5 pages
        const visiblePages = [1, 2, currentPage, totalPages - 1, totalPages];
        let gap = false;
        const finalNumbers = [];
    
        for (let i = 1; i <= totalPages; i++) {
        if (visiblePages.includes(i)) {
            finalNumbers.push(i);
            gap = false;
        } else if (!gap) {
            finalNumbers.push('...');
            gap = true;
        }
        }
    
        return finalNumbers;
    };
    
    const numbers = getVisiblePageNumbers(currentPage, npage);
    // to this code for pagination

    return (
        <>
        <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '5px', cursor: 'default' }} className="text-center"> Cloth Master </h1>
        <NavBarMain />
        <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
        <div className="row" styles={{backgroundColor: 'lightgreen'}}>
            <div className="col-md-12">
                <form onSubmit={handleSubmit} method="POST" id="form1">
                    <div className="row mb-3">
                        <div className='col-md-3'>
                            <label htmlFor="cloth_type" className="form-label">Cloth type</label>
                            <input type="text" className="form-control" name="cloth_type" value={formData.cloth_type} onChange={handleInputChange} />  
                            {errors.cloth_type && <span style={{color: 'red', fontSize: '10px'}}>{errors.cloth_type}</span>}                              
                        </div>
                        <input type="hidden" className="form-control" name="operationtype" value={formData.operationtype} onChange={handleInputChange} /> 
                        <input type="hidden" className="form-control" name="_id" value={formData._id} onChange={handleInputChange} /> 
                        <div className='col-md-3'>
                            <label htmlFor="washing_charges_rs" className="form-label">Charges Rs</label>
                            <input type="number" className="form-control" name="charges_rs" placeholder='0.00' value={formData.charges_rs} onChange={handleInputChange} />
                            {errors.charges_rs && <span style={{color: 'red', fontSize: '10px'}}>{errors.charges_rs}</span>}    
                        </div>
                        <div className='col-md-3'>
                            <label htmlFor="cloth_img" className="form-label">Put Cloth Image link here</label>
                            <input type="text" className="form-control" name="cloth_img" placeholder='paste link here' value={formData.cloth_img} onChange={handleInputChange} />
                        </div>

                        <div className='col-md-3' style={{marginTop: 33}}>
                            <button type="submit" className="btn btn-primary w-100" >{formData.operationtype == 'Add Cloth' ? 'Add Cloth' : 'Edit Cloth'}</button>
                        </div>
                    </div>                       
                </form>
                
                <div className='row'>
                    <div className='col-12 md-offset-10 col-md-2'>
                        <input
                        type="text"
                        className='form-control'
                        placeholder="Search..."
                        value={searchKeyword}
                        onChange={handleSearchInputChange}
                        />
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-md-12 table-responsive-xl">
                        <table className="table table-bordered">
                            <thead>
                            <tr >
                            <th>Cloth type</th>
                            <th>Washing Charges Rs.</th>
                            <th>Cloth Image</th>
                            <th>Action</th>
                            </tr>
                            </thead>
                        
                            <tbody>
                            {records.map((c, index) => (
                            <tr key={c._id}>
                                <td style={{textAlign: 'right'}}>{c.cloth_type}</td>
                                <td style={{textAlign: 'right'}}>{c.charges_rs}</td>
                                <td style={{textAlign: 'right'}}><img
                                                        src={c.cloth_img}
                                                        alt={`Image for ${c.cloth_type}`}
                                                        style={{ maxWidth: '100px', maxHeight: '100px', marginLeft: '10px' }}
                                                        /></td>
                                <td style={{ display: 'flex', justifyContent: 'flex-end'}}> {/* Flexbox container */}
                                    <button onClick={() => getdatabyrecordarray(c)} className="btn btn-info">Edit</button>
                                    <button onClick={(event) => confirmDelete(event, c._id)} className="btn btn-danger">Delete</button>
                                </td>
                                </tr>
                            ))}    
                            </tbody>
                        </table>

                        <nav style={{float: 'right' }}>
                            <ul className='pagination'>
                                <li className='page-item'>
                                    <a href='#' className='page-link'
                                    onClick={prePage}>Prev</a>
                                </li>
                                
                                {
                                    numbers.map((n, i) => (
                                    <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                    {n === '...' ? (
                                        <span className="page-link disabled">...</span>
                                    ) : (
                                        <a href="#" className="page-link" onClick={() => ChangeCpage(n)}>
                                        {n}
                                        </a>
                                    )}
                                    </li>
                                ))}

                                <li className='page-item'>
                                    <a href='#' className='page-link'
                                    onClick={nextPage}>Next</a>
                                </li>
                            </ul>
                        </nav>

                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
    );

    function prePage() {
        if(currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    function ChangeCpage(id) {
        setCurrentPage(id)
    }

    function nextPage() {
        if(currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    } 

}

export default ClothMaster;