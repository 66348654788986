import React, { useEffect, useState } from 'react';
// import { Navbar, Nav, NavDropdown, NavbarText, NavbarBrand, NavbarOffcanvas, NavbarToggle } from 'react-bootstrap';

import {
  Navbar,
  Nav,
  NavDropdown,
  NavbarText,
  NavbarBrand,
  NavbarOffcanvas,
  NavbarToggle,
  Button,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/navbar1.css';
import {useNavigate, json, Navigate} from 'react-router-dom';
import axios from 'axios';

function NavBarMain() {

    const apireq05042024 = process.env.REACT_APP_SERVER_API_URL;
    
    const navigate = useNavigate();
    const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
    const [lastActivityTime, setLastActivityTime] = useState(Date.now());

    const usernameglobal = JSON.parse(localStorage.getItem('username'));
    const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
          if(response.data == "logout") {
            navigatetologin();
          }
          setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      // Initial fetch if usernameglobal is available
      if (usernameglobal) {
        fetchData();
      }

      // Set up interval to fetch data every 5 minutes
      const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }, [usernameglobal]); // Dependency array includes usernameglobal

    useEffect(() => {
      if (usernameglobal === null) {
        navigate('/LoginPage');
      } else {
        // Reset last activity time whenever there's user interaction
        const handleUserActivity = () => {
          setLastActivityTime(Date.now());
        };

        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('touchstart', handleUserActivity); // For touch devices

        return () => {
          window.removeEventListener('mousemove', handleUserActivity);
          window.removeEventListener('keydown', handleUserActivity);
          window.removeEventListener('touchstart', handleUserActivity);
        };
      }
    }, [usernameglobal, navigate]);

    useEffect(() => {
      const sessionTimeout = setTimeout(() => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - lastActivityTime;

        if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
          console.log('Logging out due to inactivity...');
          navigatetologin();
        } else {
          // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
          sessionTimeout.refresh();
        }
      }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

      return () => clearTimeout(sessionTimeout);
    }, [lastActivityTime, navigatetologin]);

    // console.log(UserMasterDataGlobal[0].usertype);
    async function navigatetologin() {
      const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
      localStorage.removeItem('username');
      localStorage.removeItem('localstorage_id');
      navigate('/LoginPage');
    }

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint according to your needs
      };
      handleResize();
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [show, setShow] = useState(false); // State for controlling offcanvas visibility

  const handleClose = () => setShow(false); // Function to hide the offcanvas


  return (
    <>
      <Navbar
        style={{
          marginTop: -8,
          marginBottom: 18,
          color: 'white',
          top: 0,
          position: 'sticky',
          zIndex: 111,
        }}
        expand="lg"
        bg="dark"
        variant="light"

        // use this color to navbar letter - background-color: #ffd716 !important;
      >
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setShow(true)} />
        <NavbarOffcanvas id="basic-navbar-nav" show={show} onHide={handleClose}>
          <Nav className="mr-auto">
            <NavDropdown title="Master" id="basic-nav-dropdown">
              <NavDropdown.Item href="/CustomerMaster">Customer Master</NavDropdown.Item>
              <NavDropdown.Item href="/ClothMaster">Cloth Master</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Transaction" id="basic-nav-dropdown">
              {/* <NavDropdown.Item href="#">Purchase</NavDropdown.Item> */}
              <NavDropdown.Item href="/InwardAndBillingForm">Inward and billing</NavDropdown.Item>
              <NavDropdown.Item href="/JobCompletionForm">Job Completion Form</NavDropdown.Item>
              <NavDropdown.Item href="/Readytodeliver">Ready to deliver</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Report" id="basic-nav-dropdown">
              {/* <NavDropdown.Item href="#">Purchase</NavDropdown.Item> */}
              <NavDropdown.Item href="/OutStandingReport">OutStanding Report</NavDropdown.Item>
              <NavDropdown.Item href="/CrossTypeReport">Cross Tab Report</NavDropdown.Item>
              <NavDropdown.Item href="/DailySummaryReport">Daily Summary Report</NavDropdown.Item>
            </NavDropdown>          
            </Nav>
            {isMobile && ( // Only render close button on mobile screens
              <Button variant="light" className="close-button" onClick={handleClose}>
                <span aria-hidden="true" style={{fontSize: '21px'}}>&times;</span>
              </Button>
            )}

        {isMobile && (
          <Nav className="ml-auto"> {/* This will align the logout button to the right */}
            <Nav.Link onClick={navigatetologin} style={{ color: 'black', fontSize: 'large', fontWeight: '500' }}>
              Logout
            </Nav.Link>
          </Nav>
        )}
        </NavbarOffcanvas>
        {!isMobile && (
          <Nav className="ml-auto"> {/* This will align the logout button to the right */}
            <Nav.Link onClick={navigatetologin} style={{ color: 'black', fontSize: 'large', fontWeight: '500' }}>
              Logout
            </Nav.Link>
          </Nav>
        )}
      </Navbar>
    </>
  );
}

export default NavBarMain;