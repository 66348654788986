import React from 'react';

const sampledata = [
  { customer_name: 'Alice', cloths: ['0', '1', '2','0'] },
  { customer_name: 'Bob', cloths: ['2', '0', '0','1'] },
  { customer_name: 'Jacky', cloths: ['2','10', '0', '1'] },
  { customer_name: 'Tom', cloths: ['2', '0', '1','4'] },
];

const CrossTab = ({ data }) => {
  // Get all unique cloth types
  const clothTypes = [...new Set(data.flatMap(item => item.cloths))];

  return (
    <table>
      <thead>
        <tr>
          <th>Customer Name</th>
          {clothTypes.map((cloth, index) => (
            <th key={index}>{`Cloth ${cloth}`}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            <td>{row.customer_name}</td>
            {clothTypes.map((cloth, colIndex) => (
              <td key={colIndex}>
                {row.cloths.filter(item => item === cloth).length}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const App1 = () => {
  return (
    <div>
      <h1>Cross Tab Example</h1>
      <CrossTab data={sampledata} />
    </div>
  );
};

export default App1;
