// ||Shree ganeshay namha ||

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CustomerMaster from './pages/CustomerMaster';
import ClothMaster from './pages/ClothMaster';
import InwardAndBillingForm from './pages/InwardAndBillingForm';
import JobCompletionForm from './pages/JobCompletionForm';
import MainPage from './pages/MainPage';
import OutStandingReport from './pages/Reports/OutStandingReport';
import CrossTypeReport from './pages/Reports/CrossTypeReport';
import App1 from './pages/Reports/Cloths';
import Readytodeliver from './pages/Readytodeliver';
import DailySummaryReport from './pages/Reports/DailySummaryReport';
import LoginPage from './pages/LoginPage';
import Bill from './pages/Reports/Bill';

const App = () => {

    // const BASE_URL = process.env.REACT_APP_SERVER_API_URL;
    // console.log(process.env.REACT_APP_SERVER_API_URL);

    return ( <
        Router >
        <
        Routes >
        <
        Route path = "/"
        exact element = { < InwardAndBillingForm / > }
        /> <
        Route path = "/CustomerMaster"
        element = { < CustomerMaster / > }
        /> <
        Route path = "/ClothMaster"
        element = { < ClothMaster / > }
        /> <
        Route path = "/InwardAndBillingForm"
        element = { < InwardAndBillingForm / > }
        /> <
        Route path = "/JobCompletionForm"
        element = { < JobCompletionForm / > }
        />  <
        Route path = "/OutStandingReport"
        element = { < OutStandingReport / > }
        />  <
        Route path = "/CrossTypeReport"
        element = { < CrossTypeReport / > }
        />  <
        Route path = "/crosstab"
        element = { < App1 / > }
        />  <
        Route path = "/Readytodeliver"
        element = { < Readytodeliver / > }
        />  <
        Route path = "/DailySummaryReport"
        element = { < DailySummaryReport / > }
        />  <
        Route path = "/LoginPage"
        element = { < LoginPage / > }
        /> <
        Route path = "/Bill/:BillId"
        element = { < Bill / > }
        />  < /
        Routes > <
        /Router>
    );

};

export default App;