import React, { useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import NavBarMain from './NavBarMain';
import './css/CustomerMaster.css';
import { useNavigate} from 'react-router-dom';

function CustomerMaster() {

    const apireq05042024 = process.env.REACT_APP_SERVER_API_URL;
        
    const navigate = useNavigate();
    const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
    const [lastActivityTime, setLastActivityTime] = useState(Date.now());

    const usernameglobal = JSON.parse(localStorage.getItem('username'));
    const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
          if(response.data == "logout") {
            navigatetologin();
          }
          setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      // Initial fetch if usernameglobal is available
      if (usernameglobal) {
        fetchData();
      }

      // Set up interval to fetch data every 5 minutes
      const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }, [usernameglobal]); // Dependency array includes usernameglobal

    useEffect(() => {
      if (usernameglobal === null) {
        navigate('/LoginPage');
      } else {
        // Reset last activity time whenever there's user interaction
        const handleUserActivity = () => {
          setLastActivityTime(Date.now());
        };

        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('touchstart', handleUserActivity); // For touch devices

        return () => {
          window.removeEventListener('mousemove', handleUserActivity);
          window.removeEventListener('keydown', handleUserActivity);
          window.removeEventListener('touchstart', handleUserActivity);
        };
      }
    }, [usernameglobal, navigate]);

    useEffect(() => {
      const sessionTimeout = setTimeout(() => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - lastActivityTime;

        if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
          console.log('Logging out due to inactivity...');
          navigatetologin();
        } else {
          // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
          sessionTimeout.refresh();
        }
      }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

      return () => clearTimeout(sessionTimeout);
    }, [lastActivityTime, navigatetologin]);

    // console.log(UserMasterDataGlobal[0].usertype);
    async function navigatetologin() {
      const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
      localStorage.removeItem('username');
      localStorage.removeItem('localstorage_id');
      navigate('/LoginPage');
    }

    const MySwal = withReactContent(Swal);

    const [formData, setFormData] = useState({
        _id: '',
        customer_name: '',
        customer_mobile: '',
        address: '',
        city: '',
        opbal: '',
        clbal: '',
        operationtype: 'Add Customer',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        
        const updatedFormData = {
            ...formData,
            [name]: value,
        };

        setFormData(updatedFormData);
    };

    const [CustomerMasterData, setCustomerMasterData] = useState([]);

    // useEffect(() => {
    //     // Fetch data from the specified URL
    //     if(UserMasterDataGlobal?.[0]?.Companyid){
    //         const data = {
    //             Companyid: UserMasterDataGlobal?.[0]?.Companyid,
    //             centerid: UserMasterDataGlobal?.[0]?.centerid,
    //             userid: UserMasterDataGlobal?.[0]?._id,
    //             username: usernameglobal,
    //         }
    //         // console.log(data);
    //         axios.get(process.env.REACT_APP_SERVER_API_URL+'CustomerMaster/show', data)  
    //             .then((response) => {
    //                 console.log(response.data);
    //                 return;
    //             setCustomerMasterData(response.data);
    //             })
    //             .catch((error) => console.error('Error fetching data:', error));
    //     }
    // }, [UserMasterDataGlobal]);

    useEffect(() => {
        // Fetch data from the specified URL
        const fetchData = async () => {
            if(UserMasterDataGlobal?.[0]?.Companyid){
                const data = {
                    Companyid: UserMasterDataGlobal?.[0]?.Companyid,
                    userid: UserMasterDataGlobal?.[0]?._id,
                    username: usernameglobal,
                };
                try {
                    const response = await axios.get(process.env.REACT_APP_SERVER_API_URL+'CustomerMaster/show', { params: data });
                    // console.log(response.data);
                    // return;
                    setCustomerMasterData(response.data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }
        };
    
        fetchData();
    }, [UserMasterDataGlobal, usernameglobal]);
    



  function confirmDelete(event, id) {
        event.preventDefault();

        MySwal.fire({
            title: 'Are you sure you want to delete the record?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                if (result.value) {
                // window.location.href = $(this).attr('href');
                deleteData(id);
                }
            }
        })
    }

    async function deleteData(id) {

        const response = await axios.delete(process.env.REACT_APP_SERVER_API_URL+`CustomerMaster/delete/${id}`);
        
        if (response.status === 200) {

            if(response.data==="Customer deleted successfully") {
                MySwal.fire({
                    html: <b>Record deleted Sucessfully!</b>,
                    icon: 'success'
                })
                setCustomerMasterData((prevData) => prevData.filter((record) => record._id !== id));
            } 
        } 
    }

    function getdatabyrecordarray(record) {

        setFormData({
            _id: record._id,
            customer_name: record.customer_name,
            customer_mobile: record.customer_mobile,
            address: record.address,
            city: record.city,
            opbal: record.opbal,
            clbal: record.clbal,
            operationtype: 'Edit Customer',
        });
        // following code is to go to top of the page after clicking on edit button.
        window.scrollTo(0, 0)
    }

    const [errors, seterrors] = useState({});

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        const formData = new FormData(event.target);
        
        var validationerrors = {};

        if(formData.get('customer_name').trim() !== '') {
        }else{
          validationerrors.customer_name = "Please enter a valid customer name.";
        } 
        
        if(formData.get('customer_mobile').trim() !== '') {
            if(formData.get('customer_mobile').length !== 10){
                validationerrors.customer_mobile = "Please enter a valid mobile number.";
            }
        }else{
          
        } 

        seterrors(validationerrors);

        if (Object.keys(validationerrors).length == 0) {
            
            var data = {
                Companyid: UserMasterDataGlobal?.[0]?.Companyid,
                userid: UserMasterDataGlobal?.[0]?._id,
                customer_name: formData.get('customer_name').trim(),
                customer_mobile:formData.get('customer_mobile').trim(),
                address:formData.get('address').trim(),
                city:formData.get('city'),
                opbal:formData.get('opbal'),
                clbal:formData.get('clbal'),
                operationtype:formData.get('operationtype'),
                _id:formData.get('_id'),
            };

            if(data.operationtype == "Add Customer") {
                try {
                    const response = await axios.post(process.env.REACT_APP_SERVER_API_URL+'CustomerMaster/insert', data); // Correct the URL format
                    if(response.data.message == "Data saved successfully"){
                        MySwal.fire({
                            html: <b>Record Saved Sucessfully!</b>,
                            icon: 'success'
                        })
                    }
                    const newDataItem = {
                        ...data,
                        _id: response.data.customer._id,
                    };

                    setCustomerMasterData((prevData) => {
                        return [newDataItem, ...prevData];
                    });

                    emptyinputs();
                } catch (error) {
                    console.error('Error:', error);
                }
            } else {

                try {
                    const response = await axios.post(process.env.REACT_APP_SERVER_API_URL+'CustomerMaster/update', data); // Correct the URL format
                    console.log(response.data);
                    if(response.data.message == "Data updated successfully"){
                        MySwal.fire({
                            html: <b>Record updated Sucessfully!</b>,
                            icon: 'success'
                        })
                        
                        setCustomerMasterData((prevData) => {
                            const updatedData = [...prevData];
                            const index = updatedData.findIndex((record) => record._id == data._id);
                            
                            //we are getting clbal value back because if user updates opbal 
                            //then we have put updated clbal in the html table and to show
                            //to users.
                            const newDataItem = {
                                ...data,
                                clbal: response.data.UpdatedClBal,
                            };

                            if (index !== -1) {
                              updatedData[index] = { ...updatedData[index], ...newDataItem };
                            }           
                              
                            return updatedData;
                        });
                        // console.log(CustomerMasterData);
                        emptyinputs();
                    }                    
                } catch (error) {
                    console.error('Error:', error);
                }
            }

        } else {
            console.log(validationerrors);
        }
    }

    function emptyinputs(){
        setFormData({
            _id: '',
            customer_name: '',
            customer_mobile: '',
            address: '',
            city: '',
            opbal: '',
            clbal: '',
            operationtype: 'Add Customer',
        });
    }

    function getclbalvalue() {
        if(formData.operationtype != "Edit Customer") {
            setFormData({
                ...formData,
                clbal: formData.opbal,
            })
        }
    }

    const [searchKeyword, setSearchKeyword] = useState('');
    const [filteredCustomerData, setFilteredCustomerData] = useState([]);

    function handleSearchInputChange(event) {
        setSearchKeyword(event.target.value);
        filterCustomerData(event.target.value);
    }

    function filterCustomerData(keyword) {
      const filteredData = CustomerMasterData.filter(customer => {
          return (
              customer.customer_name.toLowerCase().includes(keyword.toLowerCase())
          );
      });
      setFilteredCustomerData(filteredData);
    }

    useEffect(() => {
        setFilteredCustomerData(CustomerMasterData);
    }, [CustomerMasterData]);

    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 5;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = filteredCustomerData.slice(firstIndex, lastIndex);
    const npage = Math.ceil(filteredCustomerData.length / recordsPerPage);
    //const numbers = [...Array(npage + 1).keys()].slice(1);
    const getVisiblePageNumbers = (currentPage, totalPages) => {
        // Handle cases with 5 or fewer pages
        if (totalPages <= 5) {
        return [...Array(totalPages + 1).keys()].slice(1);
        }
    
        // Handle cases with more than 5 pages
        const visiblePages = [1, 2, currentPage, totalPages - 1, totalPages];
        let gap = false;
        const finalNumbers = [];
    
        for (let i = 1; i <= totalPages; i++) {
        if (visiblePages.includes(i)) {
            finalNumbers.push(i);
            gap = false;
        } else if (!gap) {
            finalNumbers.push('...');
            gap = true;
        }
        }
    
        return finalNumbers;
    };
    
    const numbers = getVisiblePageNumbers(currentPage, npage);

    return (
        <>
        <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '5px', cursor: 'default' }} className="text-center"> Customer Master </h1>
        <NavBarMain />
        <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
        <div className="row" styles={{backgroundColor: 'lightgreen'}}>
            <div className="col-md-12   ">
                <form onSubmit={handleSubmit} method="POST" id="form1">
                    <div className="row mb-3">
                        <div className='col-md-3'>
                            <label htmlFor="customer_name" className="form-label">Customer Name</label>
                            <input type="text" className="form-control" name="customer_name" placeholder='Type here' value={formData.customer_name} onChange={handleInputChange}/>
                            {errors.customer_name && <span style={{color: 'red', fontSize: '10px'}}>{errors.customer_name}</span>}
                        </div>
                        <div className='col-md-2'>
                            <label htmlFor="id-customer_mobile" className="form-label">Mobile No</label>
                            <input type="number" className="form-control" name="customer_mobile" placeholder='Type here' value={formData.customer_mobile} onChange={handleInputChange}/>
                            {errors.customer_mobile && <span style={{color: 'red', fontSize: '10px'}}>{errors.customer_mobile}</span>}
                        </div>

                        <div className='col-md-3'>
                            <label htmlFor="address" className="form-label">Address</label>
                            <input type="text" className="form-control" name="address" placeholder='Type here' value={formData.address} onChange={handleInputChange} />
                        </div>
                        <div className='col-md-2' style={{display: 'none'}}>
                            <label htmlFor="city" className="form-label">City</label>
                            <select
                            name="city"
                            className="form-select"
                            value={formData.city}
                            onChange={handleInputChange}
                            >
                            <option>Karad</option>
                            <option>Satara</option>
                            </select>
                        </div>

                        <div className='col-md-1'>
                            <label htmlFor="opbal" className="form-label">Op bal</label>
                            <input type="number" className="form-control" name="opbal" placeholder='0.00' value={formData.opbal} onChange={handleInputChange} onBlur={getclbalvalue} />
                        </div>  
                        <div className='col-md-1'>
                            <label htmlFor="opbal" className="form-label">Cl bal</label>
                            <input type="number" className="form-control" name="clbal" placeholder='0.00' value={Number(formData.clbal).toFixed(2)} onChange={handleInputChange} readOnly/>
                        </div>  
                    {/* </div> */}
                    <input type="hidden" className="form-control" name="_id" placeholder='0.00' value={formData._id} onChange={handleInputChange}/>
                    <input type="hidden" className="form-control" name="operationtype" placeholder='0.00' value={formData.operationtype} onChange={handleInputChange}/>
                    {/* <div className='row mb-3'> */}
                        <div className='col-md-2'>
                            <label htmlFor="AddCustomerButton" className="form-label" style={{color: 'white'}}>s</label>
                            <button type="submit" className="btn btn-primary w-100" >{formData.operationtype == 'Add Customer' ? 'Add Customer' : 'Edit Customer'}</button>
                        </div>
                    </div>
                    
                </form>
                
                <div className='row'>
                    <div className='col-12 md-offset-10 col-md-2'>
                        <input
                        type="text"
                        className='form-control'
                        placeholder="Search..."
                        value={searchKeyword}
                        onChange={handleSearchInputChange}
                        />
                    </div>
                </div>

                <div className="row mt-4">                   
                    <div className="col-md-12 table-responsive-xl">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Mobile No</th>
                            <th>City</th>
                            <th>Op Bal</th>
                            <th style={{width: '8%'}}>Action</th>
                            </tr>
                            </thead>                            
                            <tbody>
                            {records.map((c, index) => (
                            <tr key={c._id}>
                                <td style={{textAlign: 'right'}}>{c.customer_name}</td>
                                <td style={{textAlign: 'right'}}>{c.address}</td>
                                <td style={{textAlign: 'right'}}>{c.customer_mobile}</td>
                                <td style={{textAlign: 'right'}}>{c.city}</td>
                                <td style={{textAlign: 'right'}}>{c.opbal}</td>
                                <td style={{ display: 'flex', justifyContent: 'flex-end'}}>
                                    <button onClick={() => getdatabyrecordarray(c)} className="btn btn-info btn-md">Edit</button>
                                    &nbsp;<button onClick={(event) => confirmDelete(event, c._id)} className="btn btn-danger btn-md">
                                    Delete
                                    </button>
                                </td>
                            </tr>
                                ))}
                            </tbody>
                        </table>

                        <nav style={{float: 'right' }}>
                            <ul className='pagination'>
                                <li className='page-item'>
                                    <a href='#' className='page-link'
                                    onClick={prePage}>Prev</a>
                                </li>
                                
                                {
                                    numbers.map((n, i) => (
                                    <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                    {n === '...' ? (
                                        <span className="page-link disabled">...</span>
                                    ) : (
                                        <a href="#" className="page-link" onClick={() => ChangeCpage(n)}>
                                        {n}
                                        </a>
                                    )}
                                    </li>
                                ))}

                                <li className='page-item'>
                                    <a href='#' className='page-link'
                                    onClick={nextPage}>Next</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
    );


    function prePage() {
        if(currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    function ChangeCpage(id) {
        setCurrentPage(id)
    }

    function nextPage() {
        if(currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }  

}

export default CustomerMaster;