// // Import necessary React and Bootstrap components
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Card, Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function LoginPage() {

    // const apireq05042024 = process.env.REACT_APP_SERVER_API_URL+"/";
    const apireq05042024 = process.env.REACT_APP_SERVER_API_URL;

    const navigate = useNavigate();
    
    const Redirectif = () => {
        useEffect(() => {
            navigate('/');
        }, []);
    }

    const usernameglobal = JSON.parse(localStorage.getItem('username'));
    if(usernameglobal != null){
      Redirectif();
    }
    
    const MySwal = withReactContent(Swal);
    const [errors, seterrors] = useState({});

    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent form submission
        // Handle login logic here
        const formData = new FormData(event.target);
        
        var validationerrors = {};

        if(formData.get('username').trim() !== '') {
        }else{
            validationerrors.username = "Please enter a valid username.";
        }   

        if(formData.get('password').trim() !== '') {
        }else{
            validationerrors.password = "Please enter a valid password.";
        }   
        seterrors(validationerrors);
 
        if(Object.keys(validationerrors).length == 0) {
            
            const data = {
                username: formData.get('username').trim(),
                password: formData.get('password').trim()
            };

            const response = await axios.post(apireq05042024+'UserMaster/validate1', data);
            
            if (response.status === 200) {
                if(response.data.message == "found"){
                    localStorage.setItem('username', JSON.stringify(data.username));
                    localStorage.setItem('localstorage_id', JSON.stringify(response.data.localstorage_id));
                    navigate('/');
                }
                else if (response.data.message ==  "not found") {
                    MySwal.fire({
                        html: <b>Login Failed. Check your username and password again...</b>,
                        icon: 'error'
                    })
                }
                else if (response.data.message == "Already logged in from another device.") {
                    // MySwal.fire({
                    //     html: <b>Please be advised that your account is currently logged in from another device. In order to access this device, please ensure that you log out from the previously active session. Thank you for your cooperation.</b>,
                    //     icon: 'warning',
                    // });
                    MySwal.fire({
                        title: 'Please be advised that your account is currently logged in from another device. Do you want to logout from previous session?',
                        text: "",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, Logout from previous session!'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            if (result.value) {                            
                                navigatetologin(data.username);
                                setTimeout(() => {
                                    MySwal.fire({
                                        html: <b>Please Click on login again!</b>,
                                        icon: 'warning',
                                    });
                                }, 1000);
                            }
                        }
                    })
                }
            }
        }
        else {
            console.log("validation errors...");
        }
    };

    // async function LogoutFromFirstAndLoginFromNew(username, localstorage_id) {
    //     const response = await axios.get(`apireq05042024+UserMaster/LogoutFromFirstAndLoginFromNew?username=${username}&localstorage_id=${localstorage_id}`);
    //     // localStorage.removeItem('username');localStorage.removeItem('localstorage_id');
    //     localStorage.setItem("username", JSON.stringify(username));
    //     localStorage.setItem("localstorage_id", JSON.stringify(localstorage_id));
    //     navigate('/');
    // } 

    async function navigatetologin(username) {
        const response = await axios.get(apireq05042024+`UserMaster/logout?username=${username}`);
    }

    const [passwordVisible, setPasswordVisible] = useState(false);
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    return (
        // Add code here
        <div className="container vh-100 d-flex justify-content-center align-items-center">
        <div className="card text-center" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}}>
            <div className="card-header" style={{color: 'white', backgroundColor: 'rgb(66, 41, 203)', paddingTop:'15px'}}>
            <h3>ASTTK</h3>
            </div>
            <div className="card-body">
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                <label htmlFor="username" style={{float:'left'}}>Enter username:</label>
                <input
                    type="text"
                    className="form-control"
                    name="username"
                    value={formData.username}                    
                    onChange={handleInputChange}
                />
                {errors.username && <span style={{color: 'red', fontSize: '10px'}}>{errors.username}</span>}
                </div>
                {/* <div className="form-group">
                <label htmlFor="password" style={{float:'left'}}>Enter password:</label>
                <input
                    type="password"
                    className="form-control"
                    name="password"
                    value={formData.password}                    
                    onChange={handleInputChange}
                />
                {errors.password && <span style={{color: 'red', fontSize: '10px'}}>{errors.password}</span>}
                </div> */}

                <div className="form-group">
                <label htmlFor="password" style={{float:'left'}}>Enter password:</label>
                <div className="input-group">
                    <input
                        type={passwordVisible ? "text" : "password"}
                        className="form-control"
                        name="password"
                        value={formData.password}                    
                        onChange={handleInputChange}
                    />
                    <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={togglePasswordVisibility}
                    >
                        <FontAwesomeIcon icon={passwordVisible ? faEyeSlash : faEye} />
                    </button>
                </div>
                {errors.password && <span style={{color: 'red', fontSize: '10px'}}>{errors.password}</span>}
                </div>


                <button type="submit" className="btn btn-primary mt-3">
                Login
                </button>
                
            </form>
            </div>
        </div>
        </div>
    );
}

export default LoginPage;