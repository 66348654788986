import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import NavBarMain from './NavBarMain';
import { useMediaQuery } from 'react-responsive';
// import './css/JobCompletionForm.css'; // Make sure to import your CSS file

function JobCompletionForm() {
    
  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL;
        
  const navigate = useNavigate();
  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
        if(response.data == "logout") {
          navigatetologin();
        }
        setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Initial fetch if usernameglobal is available
    if (usernameglobal) {
      fetchData();
    }

    // Set up interval to fetch data every 5 minutes
    const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [usernameglobal]); // Dependency array includes usernameglobal

  useEffect(() => {
    if (usernameglobal === null) {
      navigate('/LoginPage');
    } else {
      // Reset last activity time whenever there's user interaction
      const handleUserActivity = () => {
        setLastActivityTime(Date.now());
      };

      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity); // For touch devices

      return () => {
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }
  }, [usernameglobal, navigate]);

  useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastActivityTime;

      if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
        console.log('Logging out due to inactivity...');
        navigatetologin();
      } else {
        // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
        sessionTimeout.refresh();
      }
    }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

    return () => clearTimeout(sessionTimeout);
  }, [lastActivityTime, navigatetologin]);

  // console.log(UserMasterDataGlobal[0].usertype);
  async function navigatetologin() {
    const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
    localStorage.removeItem('username');
    localStorage.removeItem('localstorage_id');
    navigate('/LoginPage');
  }

    const [CustomerMasterData, setCustomerMasterData] = useState([]);
    const MySwal = withReactContent(Swal);
    const isDesktop = useMediaQuery({ query: '(min-width: 768px)' }); // Adjust breakpoint as needed
    const [errors, seterrors] = useState({});

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      const updatedFormData = {
        ...formData,
        [name]: value,
      };

      setFormData(updatedFormData);
    };

    const [formData, setFormData] = useState({
      tilldate: '',
      MarkAsCompletedbuttontext: 'Mark as Completed!',
    });

    useEffect(() => {
      // Update the date in the formData when the component mounts
      setFormData((prevData) => ({
        ...prevData,
        tilldate : getCurrentDate(),
      }));
    }, []); 

    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

  async function viewonclick (event) {
    // function regeneraterecords(event) {
      event.preventDefault();           
      GetInProcessCloths();      
  }

  //These variables declared here to show Total of Qty and Amt at the last of table.
  var TotalOutStanding =0;
  var TotalCustomerCount =0;

  async function printContent (event) {
    event.preventDefault();

    GetInProcessCloths();
    
    const printWindow = window.open('', '_blank');

    printWindow.document.write(`
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
    <div class="table-container col-md-12 ">
      <h1 style="color: red; font-size: 40px; font-weight: bold; margin-top: 5px; text-align: center;">Outstanding Report</h1>
      
      <table class="table table-bordered mt-3">
        <thead>
          <tr>
            <th style="color: red;">Customer Name</th>
            <th style="color: red;">Outstanding</th>
            <th style="color: red;">Mobile No</th>
          </tr>
          </thead>
          <tbody>
          ${CustomerMasterData.map((c, index) => `
          <tr key=${index}>
            <td>${c.customer_name}</td>
            <td>${Number(c.clbal).toFixed(2)}</td>
            <td>${c.customer_mobile}</td>
          </tr>
        `).join('')}
                
        </tbody>
      </table>
    </div>
  `);
  
    printWindow.document.close();
    printWindow.print();
  };

  const [filteredCustomerData, setFilteredCustomerData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [SearchfromDate, setSearchfromDate] = useState("");

  useEffect(() => {   
    GetInProcessCloths();
  }, [UserMasterDataGlobal, SearchfromDate]); 

  async function GetInProcessCloths(toshowcustomermasterdata = null) {
    
    var data = {};
    if(SearchfromDate == "") {
      data = {
        Companyid: UserMasterDataGlobal?.[0]?.Companyid,
        userid: UserMasterDataGlobal?.[0]?._id,
        username: UserMasterDataGlobal?.[0]?.username,
      }
    }
    else {
      data = {
        Companyid: UserMasterDataGlobal?.[0]?.Companyid,
        userid: UserMasterDataGlobal?.[0]?._id,
        username: UserMasterDataGlobal?.[0]?.username,
        SearchfromDate: SearchfromDate,
      }
    }
    
    var response = await axios.post(process.env.REACT_APP_SERVER_API_URL+'CustomerTransactionMaster/GetInProcessData', data);
    setCustomerMasterData([]);     
    setFilteredCustomerData([]);  
    if (response.status === 200) { 
      if(response.data.length >0) {
        setCustomerMasterData(response.data);        
        setFilteredCustomerData(response.data);  
      }       
    }
            
       
  }


  function handleSearchInputChange(event) {
    setSearchKeyword(event.target.value);
    filterCustomerData(event.target.value);
}

function filterCustomerData(keyword) {
  const filteredData = CustomerMasterData.filter(customer => {
      return (
          customer.tagno.toLowerCase().includes(keyword.toLowerCase()) ||
          customer.customer_name.toLowerCase().includes(keyword.toLowerCase()) ||
          (customer.customer_mobile && customer.customer_mobile.toString().toLowerCase() == keyword.toLowerCase()) ||
          customer.deliveryon.includes(keyword)
      );
  });
  setFilteredCustomerData(filteredData);
}

const [CompletedTagnos, setCompletedTagnos] = useState([]);

async function ProcessClothCompleted(event, datafromtable) {

    var data = {
      Companyid: UserMasterDataGlobal?.[0]?.Companyid,
      userid: UserMasterDataGlobal?.[0]?._id,
      tagno: datafromtable.tagno,
      status: "Completed",
    };
    
    const response = await axios.post(process.env.REACT_APP_SERVER_API_URL+'CustomerTransactionMaster/updatetagno/', data); // Correct the URL format

    if(response.status == 200){
      if(response.data.message == "Data saved successfully"){
        setCompletedTagnos((prevTagnos) => [...prevTagnos, datafromtable.tagno]);
      }
      sendWhatsAppMessage(datafromtable);
    }

  }

  // Inside the JobCompletionForm component

const [checkedCustomers, setCheckedCustomers] = useState({});

// Function to handle checkbox change
const handleCheckboxChange = (event, tagno) => {
  const { checked } = event.target;
  setCheckedCustomers(prevState => ({
    ...prevState,
    [tagno]: checked
  }));
};

// Function to send WhatsApp message
const sendWhatsAppMessage = (customer) => {
  if (checkedCustomers[customer.tagno]) {
    if(isDesktop) {
      const whatsappUrl = 'https://web.whatsapp.com/send/?Phone=91' + customer.customer_mobile + '&text=' + encodeURIComponent('Your garments are prepared for collection. Kindly collect it at your earliest convenience.');

      window.open(whatsappUrl);
    }
    else{
      const whatsappUrl = 'https://wa.me/91' + customer.customer_mobile + '?text=' + encodeURIComponent('Your garments are prepared for collection. Kindly collect it at your earliest convenience.');

      window.open(whatsappUrl);
    }
  }
};

  function formatJSON(salesTransactions) {
    if (!salesTransactions || salesTransactions.length === 0) {
      return "No sales transactions";
    }
    return salesTransactions.map(transaction => 
      `Cloth - ${transaction.cloth_type}\nQty - ${transaction.qty}\nRate - ${transaction.charges_rs}\nAmt - ${transaction.amt}`
    ).join('\n\n');
  }

  // Helper function to get session text based on delivery time
function getSessionText(deliveryTime) {
  const time = new Date(deliveryTime).getHours();
  if (time <= 12) return 'First Session';
  else if (time <= 16) return 'Second Session';
  else return 'Third Session';
}

// Helper function to get row color based on delivery time
function getRowColor(deliveryTime) {
  const time = new Date(deliveryTime).getHours();
  if (time <= 12) return 'pink';
  else if (time <= 16) return 'yellow';
  else return 'lightblue';
}

    return(
           
      <>
      <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '5px', cursor: 'default' }} className="text-center"> Job Completion form</h1>
        {/* <nav style={{ marginTop: -8, marginBottom: 18 }} className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid" style={{color:'black', backgroundColor:'#a0ffa3',marginTop:-8}}>
            <a className="navbar-brand" href="/">Home</a>
            <ul className="navbar-nav">
                <li className="nav-item">
                  <span className="nav-link" style={{cursor: 'default'}}>Welcome Parth</span>
                </li>
            </ul>
            <a className="navbar-brand" style={{ cursor: 'pointer' }}>Log out</a>
          </div>
        </nav> */}
        <NavBarMain />
        <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
        <div className='row'>
          <div className='col-md-1 col-12'>
            <label className='form-label'>Search</label>
          </div>
          <div className='col-md-2'>
            <input
              type="text"
              className='form-control'
              value={searchKeyword}
              onChange={handleSearchInputChange}
            />
          </div>

          <div className='col-md-1 col-12'>
            <label className='form-label'>Date</label>
          </div>
          <div className='col-md-2'>
            <input
              type="date"
              className='form-control'
              value={SearchfromDate}
              onChange={(e) => setSearchfromDate(e.target.value)}
            />
          </div>

        </div>

        <div className="row mt-2">

        <div className="col-md-12 table-responsive-xl">
        {/* below table-container code addded to keep table size fixed still its records increases */}
          <div className="table-container" style={{ height: '350px', overflow: 'auto', borderWidth:'0px' }}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', textAlign: 'right', width: '1%'}}>Tag No</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', textAlign: 'right', width: '2%'}}>Customer Name</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', textAlign: 'right', width: '6%'}}>Delivery Date</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', textAlign: 'right', width: '6%'}}>Session</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', textAlign: 'right', width: '2%'}}>Balance</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', textAlign: 'right', width: '2%'}}>Mobile No</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', textAlign: 'center', width: '0.5%'}}>Whatsapp</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', textAlign: 'right', width: '2%'}}>Status update</th>
                </tr>
              </thead>
              <tbody>

                {filteredCustomerData.map(customer => (

                    <tr key={customer.tagno} data-toggle="tooltip" data-placement="right" title={formatJSON(customer.salesTransactions)}>
                      <td style={{textAlign: 'right', backgroundColor: getRowColor(customer.deliveryon)}}>{customer.tagno}</td>
                      <td style={{textAlign: 'right', backgroundColor: getRowColor(customer.deliveryon)}}>{customer.customer_name}</td>
                      <td style={{textAlign: 'right', backgroundColor: getRowColor(customer.deliveryon)}}>                        
                        {new Date(customer.deliveryon).toLocaleDateString('en-GB', { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })}
                      </td>
                      <td style={{textAlign: 'right', backgroundColor: getRowColor(customer.deliveryon)}}>     
                        {getSessionText(customer.deliveryon)}
                      </td>
                      <td style={{textAlign: 'right', backgroundColor: getRowColor(customer.deliveryon)}}>{customer.clbal}</td>
                      <td style={{textAlign: 'right', backgroundColor: getRowColor(customer.deliveryon)}}>{customer.customer_mobile}</td>
                                              
                        <td style={{ textAlign: 'center', backgroundColor: getRowColor(customer.deliveryon)}}>
                        <input
                            type="checkbox"
                            checked={checkedCustomers[customer.tagno] || false}
                            onChange={(event) => handleCheckboxChange(event, customer.tagno)}
                        />
                        </td>

                        <td style={{textAlign: 'right', backgroundColor: getRowColor(customer.deliveryon)}}>
                            <button
                                onClick={(event) => ProcessClothCompleted(event, customer)}
                                className={`btn btn-${CompletedTagnos.includes(customer.tagno) ? 'success' : 'info'} btn-sm`}
                                style={{ backgroundColor: CompletedTagnos.includes(customer.tagno) ? '#28a745' : 'green', color: 'white' }}

                                >
                                {CompletedTagnos.includes(customer.tagno) ? 'Completed✅' : formData.MarkAsCompletedbuttontext}
                            </button>
                        </td>
                  </tr>
                ))}

              </tbody>
            </table>
          </div>        

        </div>
      </div>

    </div>
    </>
    );

}

export default JobCompletionForm;