import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate, generatePath, Link, BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import NavBarMain from '../NavBarMain';

function OutStandingReport() {

  const apireq05042024 = process.env.REACT_APP_SERVER_API_URL;
        
  const navigate = useNavigate();
  const [UserMasterDataGlobal, setUserMasterDataGlobal] = useState([]);
  const [lastActivityTime, setLastActivityTime] = useState(Date.now());

  const usernameglobal = JSON.parse(localStorage.getItem('username'));
  const localstorage_id = JSON.parse(localStorage.getItem('localstorage_id'));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apireq05042024+`UserMaster/getdatabyusername?username=${usernameglobal}&localstorage_id=${localstorage_id}`);
        if(response.data == "logout") {
          navigatetologin();
        }
        setUserMasterDataGlobal(response.data.UserMasterDataGlobal);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    // Initial fetch if usernameglobal is available
    if (usernameglobal) {
      fetchData();
    }

    // Set up interval to fetch data every 5 minutes
    const intervalId = setInterval(fetchData, 5 * 60 * 1000); // 5 minutes in milliseconds

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [usernameglobal]); // Dependency array includes usernameglobal

  useEffect(() => {
    if (usernameglobal === null) {
      navigate('/LoginPage');
    } else {
      // Reset last activity time whenever there's user interaction
      const handleUserActivity = () => {
        setLastActivityTime(Date.now());
      };

      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('keydown', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity); // For touch devices

      return () => {
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('keydown', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
      };
    }
  }, [usernameglobal, navigate]);

  useEffect(() => {
    const sessionTimeout = setTimeout(() => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - lastActivityTime;

      if (elapsedTime > 15 * 60 * 1000) { // Log out after 15 minutes of inactivity
        console.log('Logging out due to inactivity...');
        navigatetologin();
      } else {
        // If there's less than 15 minutes left, reset the timeout to avoid unnecessary checks
        sessionTimeout.refresh();
      }
    }, 15 * 60 * 1000); // Check for inactivity every 15 minutes (converted to milliseconds)

    return () => clearTimeout(sessionTimeout);
  }, [lastActivityTime, navigatetologin]);

  // console.log(UserMasterDataGlobal[0].usertype);
  async function navigatetologin() {
    const response = await axios.get(apireq05042024+`UserMaster/logout?username=${usernameglobal}`);
    localStorage.removeItem('username');
    localStorage.removeItem('localstorage_id');
    navigate('/LoginPage');
  }

    const [CustomerMasterData, setCustomerMasterData] = useState([]);

    const [errors, seterrors] = useState({});

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      const updatedFormData = {
        ...formData,
        [name]: value,
      };

      setFormData(updatedFormData);
    };

    const [formData, setFormData] = useState({
      tilldate: '',
    });

    useEffect(() => {
      // Update the date in the formData when the component mounts
      setFormData((prevData) => ({
        ...prevData,
        tilldate : getCurrentDate(),
      }));
    }, []); 

    const getCurrentDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

  async function viewonclick (event) {
    // function regeneraterecords(event) {
      event.preventDefault();     
      
      GetOutStandingReport();      
  }

    //These variables declared here to show Total of Qty and Amt at the last of table.
    var TotalOutStanding =0;
    var TotalCustomerCount =0;

  async function printContent (event) {

    event.preventDefault();

    GetOutStandingReport();
    
    const printWindow = window.open('', '_blank');

    printWindow.document.write(`
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous">
    <div class="table-container col-md-12 ">
      <h1 style="color: red; font-size: 30px; font-weight: bold; margin-top: 5px; text-align: center;">Outstanding Report</h1>
      
      <table class="table table-bordered mt-3">
        <thead>
          <tr>
            <th style="color: red;">Customer Name</th>
            <th style="color: red; text-align: right;">Outstanding</th>
            <th style="color: red; text-align: right;">Mobile No</th>
          </tr>
          </thead>
          <tbody>
          ${CustomerMasterData.map((c, index) => `
          <tr key=${index}>
            <td>${c.customer_name}</td>
            <td style="text-align: right;">${Number(c.clbal).toFixed(2)}</td>
            <td style="text-align: right;">${c.customer_mobile !== null ? c.customer_mobile : ""}</td>
          </tr>
        `).join('')}
                
        </tbody>

        <tfoot>
          <tr>
            <td style="font-weight: bolder; font-size: 18px;">Total</td>
            <td style="text-align: right; font-weight: bolder; font-size: 18px;">${Number(TotalOutStanding).toFixed(2)}</td>
            <td></td>
          </tr>
        </tfoot>


      </table>
    </div>
  `);
  
    printWindow.document.close();
  };

  async function GetOutStandingReport(toshowcustomermasterdata = null) {

      var validationerrors = {};

      if(formData.tilldate !== '') {
      }else{
        validationerrors.tilldate = "Please select prefered to date from datepicker";
      }

      seterrors(validationerrors);

      if(Object.keys(validationerrors).length == 0) {

        var data = {
          tilldate: formData.tilldate || getCurrentDate(),
          Companyid: UserMasterDataGlobal?.[0]?.Companyid,
          userid: UserMasterDataGlobal?.[0]?._id,
          username: UserMasterDataGlobal?.[0]?.username,
        };

        setFormData({
          ...formData,
          tilldate: data.tilldate,
        });

        try {          
                   
          if(toshowcustomermasterdata !== "notshow") {
            var response  = "";
      
            response = await axios.post(process.env.REACT_APP_SERVER_API_URL+'CustomerMaster/GetOutStandingReport/', data);
            setCustomerMasterData([]);                 
            if (response.status === 200) { 
              // console.log(response.data);
              // return;      
              if(response.data.length >0) {
                setCustomerMasterData(response.data);          
              }       
            }             
          }

        } catch {

        } 

      }  

  }

    return(
           
      <>
      <h1 style={{ color: 'white', backgroundColor: '#4229cb', padding: '5px', cursor: 'default' }} className="text-center"> Outstanding list</h1>
      <NavBarMain />
      <div className="container-fluid" style={{color: '#4229cb', fontWeight: '500' ,backgroundColor: 'rgb(255 255 255)'}} >
      <div className="row" styles={{backgroundColor: 'lightgreen'}}>
        {/* <div className="col-md-6 offset-md-3"> */}
          <form method='get'> 

            <div className="row mb-2">

              <div className="col-md-2" style={{width: '12%'}}>
                <label htmlFor="tilldate" className="form-label">Till Date</label>
                <input type="date" className="form-control" name="tilldate" id="id-tilldate" value={formData.tilldate} onChange={handleInputChange} required/>
                {errors.tilldate && <span style={{color: 'red', fontSize: '10px'}}>{errors.tilldate}</span>}
              </div>

              <div className="col-md-1">
                <button type="submit" className="btn btn-primary w-100" style={{marginTop: '32px'}} onClick={(event) => viewonclick(event)}>View</button>
                {/* <button type="submit" className="btn btn-primary w-100" style={{marginTop: '32px'}} >View</button> */}
              </div>

              <div className="col-md-1">
                <button className="btn btn-primary w-100" style={{marginTop: '32px', backgroundColor: 'green', borderColor: 'green'}} onClick={printContent}>Print</button>
              </div>
               
            </div> 
          </form>
      </div>


      <div className="row mt-2">
        <div className="col-md-12 table-responsive-xl">
        {/* below table-container code addded to keep table size fixed still its records increases */}
          <div className="table-container" style={{ height: '350px', overflow: 'auto', borderWidth:'0px' }}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '5%'}}>Customer Name</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '5%'}}>Outstanding</th>
                  <th style={{backgroundColor:'#726200', color:"white", opacity:'0.8', width: '9%'}}>Mobile No</th>
                </tr>
              </thead>
              <tbody>
              {CustomerMasterData.map((c, index) => {
                TotalOutStanding = Number(TotalOutStanding) + Number(c.clbal);
                TotalCustomerCount = TotalCustomerCount + 1;
                return (
                  <tr key={c.customer_mp_id}>
                    <td>{c.customer_name}</td>
                    <td style={{textAlign: 'right'}}>{Number(c.clbal).toFixed(2)}</td>
                    <td style={{textAlign: 'right'}}>{c.customer_mobile}</td> 
                  </tr>
                );
              })}

              </tbody>            

            </table>
          </div>

          <div className="row mb-3">

             <div className='col-md-3 offset-md-2' style={{marginTop:-25, padding: 18, height: '80px', backgroundColor: 'rgb(41 113 203)', border: '4px solid white',textAlign: 'center', boxShadow : '3px 7px 5px black', borderRadius: '13px'}}>
              <span style={{fontSize: 20, color: 'white'}}>Total Customers = {TotalCustomerCount}</span>
            </div>
            <div className='col-md-3 offset-md-1' style={{marginTop:-25, padding: 18, height: '80px', backgroundColor: 'rgb(255, 12, 12)', border: '4px solid white',textAlign: 'center', boxShadow : '3px 7px 5px black', borderRadius: '13px'}}>
              <span style={{fontSize: 20, color: 'white'}}>Total outstanding = {Number(TotalOutStanding).toFixed(2)}</span>
            </div>
          </div>

        </div>
      </div>

    </div>
    </>
    );

}

export default OutStandingReport;